$(function () {
    $('.map-container').mapify({
        points: [
            {
                title: 'Ebor Windows',
                marker: true,
                lat: 53.97207,
                lng: -1.077414
            }
        ],
        zoom: 17,
        responsive: true,
        key: 'AIzaSyCbudcG-Mww0mvAWjbfC8muQiYZwuM3258',
        options: {
            fullscreenControl: true,
            scrollwheel: false
        }
    });
});
