(function($) {

$(document).ready(function() {
    $('.mobile-nav').on('click', function(){
       $(".nav-wrap").toggleClass('-closed');
    });
});

function menuClass() {

    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (width <= em2px(40)) {
        $(".nav").addClass("mobile-nav");
        $(".nav-wrap").addClass("-closed");
    }
    else {
        $(".nav-wrap").removeClass("-closed");
        $(".nav").removeClass("mobile-nav");
    }
}

function em2px(input) {
    var emSize = parseFloat($("body").css("font-size"));
    return (emSize * input);
}

menuClass();

})(jQuery);
